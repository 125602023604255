import { graphql } from "gatsby";
import React, { useState } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import formErrors from "../Global/formErrors";
import Layout from "./layout";

const Careers = ({ data }) => {

  const [isSent, setIsSent] = useState(false);

  const submitForm = (values) => {
    axios({
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      url: "https://getform.io/f/df28ff2c-de3e-458d-94a8-a0ad49dbf98c",
      data: JSON.stringify(values),
    })
      .then((r) => {
        console.log("Thanks!", values);
      })
      .catch((r) => {
        console.log("Failed", values);
      });
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    eMail: Yup.string()
      .email("Please give your E-Mail")
      .required("Please fill your E-Mail Address"),
      mobileNumber: Yup.string().matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      }),
    position: Yup.string().required("This Field is Required"),
    url: Yup.string()
      .url("Please enter a Valid URL")
      .required("This Field is Required"),
    mainMessage: Yup.string().required("This Field is Required"),
  });

  return (
    <>
      <Layout>
        <main className="contact-options-grid">
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2">
            <ul className="flex flex-col space-y-2 bg-gray-900 p-4 sm:p-8 lg:p-12">
              <li>
                <h1 className="text-2xl text-blue-500 font-medium">Careers</h1>
              </li>
              <div className="flex flex-col space-y-6">
                <li>
                  <h2 className="text-3xl leading-normal text-gray-300 font-light">
                    {data.prismicJobOpening.data.job_title.text}
                  </h2>
                  <p className="text-lg leading-relaxed text-gray-400 font-light">
                    {data.prismicJobOpening.data.job_location}
                  </p>
                </li>
                <li className="flex flex-col space-y-2">
                  <p className="text-lg font-medium text-blue-500 leading-relaxed">
                    Monthly Salary Range:
                  </p>
                  <p className="text-lg leading-relaxed text-gray-400 font-light">
                    ₹{data.prismicJobOpening.data.salary_starting} to ₹
                    {data.prismicJobOpening.data.salary_ending}
                  </p>
                </li>
              </div>
            </ul>
          </div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>
          <section className="p-8 lg:p-12 bg-gray-900 flex flex-col space-y-10 items-start">
            <ul className="flex flex-col space-y-6 items-start">
              <h4 className="text-2xl text-gray-300 font-medium">
                Job Summary
              </h4>
              <div
                className="prose mx-auto"
                dangerouslySetInnerHTML={{
                  __html: data.prismicJobOpening.data.job_summary.html,
                }}
              ></div>
            </ul>
            <ul className="flex flex-col space-y-6 items-start">
              <h4 className="text-2xl text-gray-300 font-medium">
                Key Responsibilities
              </h4>
              <div
                className="prose mx-auto"
                dangerouslySetInnerHTML={{
                  __html: data.prismicJobOpening.data.key_responsibilities.html,
                }}
              ></div>
            </ul>
            <ul className="flex flex-col space-y-6 items-start">
              <h4 className="text-2xl text-gray-300 font-medium">
                Key Qualifications
              </h4>
              <div
                className="prose mx-auto"
                dangerouslySetInnerHTML={{
                  __html: data.prismicJobOpening.data.key_qualifications.html,
                }}
              ></div>
            </ul>
            <ul className="flex flex-col space-y-6 items-start">
              <h4 className="text-2xl text-gray-300 font-medium">
                Education & Experience
              </h4>
              <div
                className="prose mx-auto"
                dangerouslySetInnerHTML={{
                  __html: data.prismicJobOpening.data.education_experience.html,
                }}
              ></div>
            </ul>
          </section>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-4">
            {!isSent ? (
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  eMail: "",
                  mobileNumber: "",
                  position: "",
                  url: "",
                  mainMessage: "",
                }}
                onSubmit={(values, actions) => {
                  submitForm(values);
                  console.log(values);
                  actions.setSubmitting(false);
                  actions.resetForm();
                }}
                validationSchema={validationSchema}
              >
                <Form className="form-grid">
                  <div className="form-field-container">
                    <label htmlFor="firstName" className="form-label">
                      First Name
                    </label>
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      className="form-input-field"
                    />
                    <ErrorMessage name="firstName" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="lastName" className="form-label">
                      Last Name
                    </label>
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      className="form-input-field"
                    />
                    <ErrorMessage name="lastName" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="eMail" className="form-label">
                      E-Mail Address
                    </label>
                    <Field
                      type="text"
                      id="eMail"
                      name="eMail"
                      placeholder="E-Mail Address"
                      className="form-input-field"
                    />
                    <ErrorMessage name="eMail" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="mobileNumber" className="form-label">
                      Mobile Number
                    </label>
                    <ul className="flex flex-row">
                      <li className="flex flex-col items-center justify-center px-3 rounded-l bg-gray-800 shadow border-gray-700 border border-r-0 text-gray-400">
                        +91
                      </li>
                      <li className="flex-1">
                        <Field
                          type="text"
                          id="mobileNumber"
                          name="mobileNumber"
                          placeholder="91234567890"
                          className="form-input-field rounded-r rounded-l-none"
                        />
                      </li>
                    </ul>
                    <ErrorMessage name="mobileNumber" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="position" className="form-label">
                      Job Position
                    </label>
                    <Field
                      type="text"
                      id="position"
                      name="position"
                      placeholder="Job Position"
                      className="form-input-field"
                    />
                    <ErrorMessage name="position" component={formErrors} />
                  </div>
                  <div className="form-field-container">
                    <label htmlFor="url" className="form-label">
                      Resume URL (Google Drive)
                    </label>
                    <Field
                      type="url"
                      id="url"
                      name="url"
                      placeholder="Resume Link (Google Drive)"
                      className="form-input-field"
                    />
                    <ErrorMessage name="companyName" component={formErrors} />
                  </div>
                  <div className="form-field-container col-span-2">
                    <label htmlFor="mainMessage" className="form-label">
                      Message
                    </label>
                    <Field
                      type="textarea"
                      as="textarea"
                      e
                      id="mainMessage"
                      name="mainMessage"
                      placeholder="Type your Message here"
                      className="form-input-field h-24"
                    />
                    <ErrorMessage name="mainMessage" component={formErrors} />
                  </div>
                  <div className="form-field-container col-span-2 flex flex-row justify-end">
                    <button
                      type="submit"
                      className="contact-form-button border-r-0"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              </Formik>
            ) : (
              <div className="flex flex-col items-center justify-center py-16 px-8">
                <p className=" text-2xl text-gray-400">
                  {" "}
                  Thank you For Submitting the Form,
                </p>
                <p className="pt-4 text-xl font-light text-gray-300">
                  Please check your Inbox! for further instructions.
                </p>
              </div>
            )}
          </div>
          <div className="bg-gray-900 p-2"></div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query CareersQuery($slug: String) {
    prismicJobOpening(data: { slug: { eq: $slug } }) {
      data {
        job_location
        salary_starting
        salary_ending
        slug
        key_responsibilities {
          html
        }
        key_qualifications {
          html
        }
        job_title {
          text
        }
        job_summary {
          html
        }
        education_experience {
          html
        }
      }
    }
  }
`;

export default Careers;
